@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;700&display=swap");
// Global Variables
$blue: #022e3e;

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

html {
}

body {
  font-family: "Bai Jamjuree", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background-size: cover;
  background-position: center;
  background-color: $blue;
}

@media (min-width: 1200px) {
  #root {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    @include center;
  }
  #Layout {
    position: relative;
    width: 375px;
    overflow: hidden;
    margin: 0 auto;
  }
}

#Layout {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#Form {
  width: 100%;
  .title {
    color: white;
    font-size: 24px;
    margin: 24px 0;
  }
  label {
    display: block;
    color: white;
    opacity: 0.5;
    margin-top: 12px;
  }
  input,
  select {
    font-family: "Bai Jamjuree", sans-serif;
    border: 0;
    width: 100% !important;
    padding: 8px 12px;
    margin-top: 4px;
    border-radius: 5px;
    font-size: 16px;
    appearance: none;
  }
  select {
    background-image: url("./down.svg");
    background-repeat: no-repeat;
    background-position: right 16px top 50%;
    background-color: white;
  }
  .time-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }
  .btn {
    border: 0;
    background-color: #b7d12a;
    margin-top: 32px;
    width: 100%;
    color: white;
    padding: 12px;
    font-size: 16px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
  }
  input[type="date"] {
    appearance: none;
    display: block;
    font-family: "Bai Jamjuree", sans-serif;
    border: 0;
    width: 100% !important;
    padding: 8px 12px;
    margin-top: 4px;
    border-radius: 5px;
    font-size: 16px;
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
    background-image: url("./calendar.svg");
    background-repeat: no-repeat;
    background-position: right 16px top 50%;
  }
  input[type="time"] {
    display: block;
    appearance: none;

    font-family: "Bai Jamjuree", sans-serif;
    border: 0;
    min-height: 36px;
    width: 100% !important;
    padding: 8px 12px;
    margin-top: 4px;
    border-radius: 5px;
    font-size: 16px;
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
    background-image: url("./clock.svg");
    background-repeat: no-repeat;
    background-position: right 16px top 50%;
  }
}

#Auschecken {
  .text {
    margin-top: 64px;
    text-align: center;
    color: white;
  }
  .btn {
    border: 0;

    background-color: #b7d12a;
    margin-top: 32px;
    width: 100%;
    color: white;
    padding: 12px;
    font-size: 16px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
  }
}

#Loader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: grey;
  opacity: 0.5;
  z-index: 99;
  svg {
    height: 100px;
    width: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

* {
  box-sizing: border-box;
}
